$(document).ready(function(){ 
   
   /*================  facebook ===============*/
    $.getScript('https://connect.facebook.net/en_US/sdk.js', function(){
        FB.init({
            appId: '1819272264960918',
            version: 'v2.5',
            xfbml : true,
            status : true,
            cookie : true, // enable cookies to allow the server to access the session
            oauth: true // enable OAuth 2.0
        });     
        $('.btn-facebook').removeAttr('disabled');
        // FB.getLoginStatus(function(response) {
        //     statusChangeCallback(response);
        // });
    });
    function statusChangeCallback(response) {
        console.log('statusChangeCallback');
        console.log(response);
        sessionStorage.token=response.authResponse.accessToken;
        // The response object is returned with a status field that lets the
        // app know the current login status of the person.
        // Full docs on the response object can be found in the documentation
        // for FB.getLoginStatus().
        var loggedin = {
                callback : function (response){
                    console.log('Successful login for: ' + response.name,response);
                    $('#msg').html('');
                    // var user_info={
                    //     username:response.name,
                    //     user_url :response.link, 
                    //     img_url : response.picture.data.url
                    // }
                    // var user_box=Mustache.render($('#user-template').html(),user_info);
                    
                    var that = $('#login_form');
                    var formData={
                        "name" : response.name,
                        "email" : response.email,
                        "fb_id" : response.id,
                        "accesstoken" : sessionStorage.token
                    };
                        
                    $.ajax({
                        type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
                        url         : 'login.php', // the url where we want to POST
                        data        : formData, // our data object
                        dataType    : 'json', // what type of data do we expect back from the server
                        encode      : true
                    })
                        // using the done promise callback
                    .done(function(data) {

                            // log data to the console so we can see
                            console.log(data); 
                            var email_div=that.children("div:nth-child(1)");
                            var pwd_div=that.children("div:nth-child(2)");
                            var msg_block=$("#msg");
                            var help_block=that.children(".help-block");
                            // here we will handle errors and validation messages
                            if ( ! data.success) {
                               
                                // handle errors for email ---------------
                                
                                if (data.errors.email) {
                                    help_block.remove(); // remove the error text
                                    email_div.addClass('has-error'); // add the error class to show red input
                                    msg_block.append('<div class="help-block"><span class="text-danger">' + data.errors.email + '</span></div>'); // add the actual error message under our input
                                }
                                if (data.errors.pwd) {
                                    help_block.remove(); // remove the error text
                                    pwd_div.addClass('has-error'); // add the error class to show red input
                                    msg_block.append('<div class="help-block"><span class="text-danger">' + data.errors.pwd + '</span></div>'); // add the actual error message under our input
                                    if(data.errors.register==1){
                                        alertify.confirm("You haven't registered for an account yet. Would you like to do so now?",function(){
                                            location.href="../register/";   
                                        },function(){
                                            
                                        }).setHeader("Registration required");
                                    }
                                }
                                if (data.errors.conn) {
                                    help_block.remove(); // remove the error text
                                    msg_block.append('<div class="help-block"><span class="text-danger">' + data.errors.conn + '</span></div>'); // add the actual error message under our input
                                }
                                 $("#orfb").fadeIn("slow");
                            } else {
                                help_block.remove(); // remove the error text
                                // ALL GOOD! just show the success message!
                                email_div.addClass('has-success');
                                pwd_div.addClass('has-success');
                                msg_block.append('<div class="help-block"><span class="text-success">' + data.message + '</span></div>');

                                // usually after form submission, you'll want to redirect
                                // window.location = '/thank-you'; // redirect a user to another page
                                alertify.success(data.message); // for now we'll just alert the user
                                //login(data.name,data.email);
                                var timing=setTimeout(function(){
                                    location.href="../";
                                },3000);
                                
                            }
                    })
                        
                            
                        // using the fail promise callback
                    .fail(function(data) {
                        alertify.alert(data.responseText).setHeader("Something Broke");
                         $("#orfb").fadeIn("slow");
                    });    
                    console.log(formData);
                }
            };
        if (response.status === 'connected') {
            // Logged into your app and Facebook.
            console.log('Welcome!  Fetching your information.... ');
            $("#orfb").fadeOut("slow");
            
            var fields={fields:"id,name,email,picture"};
            testAPI("me",fields,loggedin);
        } else if (response.status === 'not_authorized') {
            // The person is logged into Facebook, but not your app.
            $("#orfb").fadeIn();
        } else {
            // The person is not logged into Facebook, so we're not sure if
            // they are logged into this app or not.
            $("#orfb").fadeIn();
        }
    }

        // This function is called when someone finishes with the Login
        // Button.  See the onlogin handler attached to it in the sample
        // code below.
    function checkLoginState() {
        FB.getLoginStatus(function(response) {
            statusChangeCallback(response);
        });
    }
        
    
    function testAPI(id,fields,fn) {
        FB.api(id,fields, function(response) {
            fn.callback(response);
        });
    }
    function logout(){
        alertify.confirm("This action will also log you out of facebook",function(ev){
            FB.logout(function(response) {
                // user is now logged out
                console.log('Logout successful');
                $('#msg').html('You are now logged out');
                $("#orfb").show();
                $("#user-box").fadeOut().remove();
            });
        }).setHeader("Are you sure?");
    }
    function login(){
        FB.login(function(response) {
            // user is now logged out
            statusChangeCallback(response);
        }, {
            scope: 'email,public_profile', 
            return_scopes: true		
        });	
    }

    $("body").on("click",".btn-facebook",function(ev){
		ev.preventDefault();
        login();
    });	

     /*================  /facebook ===============*/
});